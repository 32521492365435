import Image from 'next/legacy/image'
import type { FC, ReactNode } from 'react'

interface TitleCardProps {
  title?: ReactNode
  centerTitle?: boolean
  showPartyBanner?: boolean
  children?: ReactNode
}

const TitleCard: FC<TitleCardProps> = ({
  title,
  showPartyBanner = false,
  centerTitle = false,
  children,
}) => {
  return (
    <div className="relative text-coolGray-800">
      {showPartyBanner && (
        <div className="absolute -right-12 -top-12 hidden h-60 w-64 md:block">
          <Image src="/images/legacy/party-banner.png" alt="party banner" layout="fill" />
        </div>
      )}

      {!!title && (
        <div className="bg-white">
          <h2
            className={`mb-6 font-sans text-3xl font-semibold text-purple-800 ${
              showPartyBanner && 'md:pr-40'
            } ${centerTitle && 'text-center'}`}
          >
            {title}
          </h2>
        </div>
      )}

      <div className={`bg-white text-xl ${!title && 'pt-12'}`}>{children}</div>
    </div>
  )
}

export default TitleCard
