import { useRouter } from 'next/router'
import { useCallback } from 'react'
import routes from '../lib/routes'

const useLoginRedirect = () => {
  const router = useRouter()
  return useCallback(() => router.push(`${routes.cart()}?source=login`), [router])
}

export default useLoginRedirect
