import type { FC } from 'react'
import React, { useState } from 'react'
import StandardIcon from '../../global/icons/StandardIcon'
import LegacyExclamationIcon from '../legacy/icons/LegacyExclamationIcon'

const Alert: FC<{ success?: boolean; message: string; onClick?: () => void }> = ({
  success = true,
  message,
  onClick,
}) => {
  const [showBanner, setShowBanner] = useState(true)

  const onClose = () => {
    if (onClick) {
      onClick()
    }
    setShowBanner(false)
  }

  if (!showBanner) {
    return null
  }

  return (
    <div
      className={`${
        success ? 'border-green-800 bg-green-100' : 'border-red-800 bg-red-100'
      } my-10 flex h-full items-center justify-between rounded-md border border-l-8 p-5`}
    >
      <div className="flex flex-row items-center align-middle">
        <div className="flex max-h-full w-32">
          {success ? (
            <svg className="h-1/2 w-1/2" width="12" height="10" viewBox="0 0 12 10" fill="none">
              <path
                d="M4.07573 8.94088L0.175729 4.96616C-0.0585762 4.72736 -0.0585762 4.34019 0.175729 4.10137L1.02424 3.23658C1.25854 2.99776 1.63846 2.99776 1.87277 3.23658L4.5 5.91412L10.1272 0.179096C10.3615 -0.0596985 10.7415 -0.0596985 10.9758 0.179096L11.8243 1.04389C12.0586 1.28268 12.0586 1.66986 11.8243 1.90867L8.37427 5.42479L4.92426 8.94091C4.68994 9.1797 4.31004 9.1797 4.07573 8.94088Z"
                fill="currentColor"
              />
            </svg>
          ) : (
            <LegacyExclamationIcon className="h-1/2 w-1/2" />
          )}
        </div>
        <p className="ml-3 text-xl font-semibold">
          <span className="box-border">{message}</span>
        </p>
      </div>

      <button className="justify-items-end" onClick={onClose}>
        <StandardIcon icon="x" alt="close" width={10} height={10} />
      </button>
    </div>
  )
}

export default Alert
